import request from '../../request'
import { modelMsg } from '../../components/modelMethods'
import { push } from 'react-router-redux'
export default {
  state: {
    name: 'EcgHospitalList',
    params: {
      page: 1,
      pageSize: 10,
      system: ''
    },
    userInfo: {},
    ecgHospitalList: [],
    hospitalList: [],
    titleList: [],
    persGroup: [],
    orgUserList: [],
    adminUserList: [],
    persGroupSuper: [
      { configKey: '机构管理员', configValue: '3' },
      { configKey: '报告医生', configValue: '2' }
    ],
    persGroupSmall: [
      // { configKey: "机构管理员", configValue: "3" },
      { configKey: '报告医生', configValue: '2' }
    ],
    hospitalCode: '',
    totalRows: 1,
    expirationTime: 0,
    dynamicCode: '',
    serialNumber: ''
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: dispatch => ({
    // async outPut() {
    //   const url = '/patient/api/screening/exportList'
    //   const params = payload
    //   cons
    // },
    async getArea(payload) {
      dispatch.UserControll.getArea(payload)
      this.getTitleList()
      // this.getPersGroupList()
    },
    async getHospitals(payload) {
      dispatch.UserControll.getHospitals(payload)
    },

    async setAdmin(payload) {
      const url = `/api/organization/setManager/${payload.id}`
      const params = payload

      const res = await request.post(url, params)

      if (res.status === 0) {
        // this.updateState({
        //   adminUserList: res.data
        // })
        modelMsg(res.status, '管理员设置成功')
        this.getEcgHospitalList()
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async addOrg(payload) {
      const url = `/api/organization/addOrganization`
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '新增成功')
        this.getEcgHospitalList()
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async editOrg(payload) {
      const url = `/api/organization/modify/${payload.id}`
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '修改成功')
        this.getEcgHospitalList()
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getUserListByOrganizationId(payload) {
      const url = `/api/user/users/${payload.id}`
      const res = await request.get(url)

      if (res.status === 0) {
        this.updateState({
          adminUserList: res.data
        })
        if (res.data.length > 0) {
          return true
        }
        return false
        // modelMsg(res.status, res.msg)
      } else {
        modelMsg(res.status, res.msg)
        return false
      }
    },
    async addEcgHospital(payload) {
      const url = '/api/organization/addOrganization'
      const params = payload
      const cb = params.cb
      delete params.cb
      const res = await request.post(url, params)

      const permsGroupId = payload.permsGroupId
      const realName = payload.realName
      if (res.status === 0) {
        cb({
          msg: `新增机构成功，当前未设置机构管理员，是否立即新增？`,
          title: '新增成功',
          titleType: 'default',
          okMsg: '新增管理员',
          modalType: 'confirm',
          okFunc: function() {
            dispatch(push(`/addEcgUser?organization=${res.data}`))
          },
          cancelFunc: function() {
            dispatch(push('/ecgHospitalList'))
          }
        })
      } else {
        modelMsg(2, res.msg)
      }
    },
    async editEcgHospital(payload) {
      const url = `/api/organization/modify/${payload.id}`
      const params = payload
      const cb = params.cb
      delete params.cb
      const res = await request.post(url, params)
      const permsGroupId = payload.permsGroupId
      const realName = payload.realName

      if (res.status === 0) {
        // if (permsGroupId == '3') {
        //   cb({
        //     msg: `管理员替换/该机构管理员已经替换为${realName}}！`,
        //     title: '新增成功',
        //     titleType: 'default',
        //     cancelFunc: function() {
        //       dispatch(push('/ecgHospitalList'))
        //     }
        //   })
        // } else {
        //   modelMsg(0, '新增用户成功！')
        // }
        modelMsg(0, '保存成功！')
        dispatch(push('/ecgHospitalList'))
      } else {
        modelMsg(res.status, res.msg)
      }
    },

    async getEcgHospitalDetail(payload) {
      const url = `/api/organization/detail/${payload.id}`
      const params = payload
      const res = await request.get(url, { params })
      console.log(res)
      if (res.status === 0) {
        this.updateState({
          userInfo: res.data
        })
      } else {
        modelMsg(0, res.msg)
      }
    },
    async modifyCertificationState(payload) {
      const url = `/api/organization/modifyState/${payload.id}`
      const params = payload
      const res = await request.post(url, params)

      if (res.status === 0) {
        modelMsg(0, res.msg)
        this.getEcgHospitalList()
      } else {
        modelMsg(0, res.msg)
      }
    },

    async getOrgUserList(payload) {
      console.log(payload)
      const params = {...payload}
      const url = '/api/organization/userList'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          orgUserList: res.data
        })
      }
    },

    async getEcgHospitalList(payload = {}, rootState) {
      this.getTitleList()
      const url = '/api/organization/page'
      const params = { ...rootState.EcgHospitalList.params, ...payload }
      const res = await request.get(url, { params })

      if (res.status === 0) {
        this.updateState({
          ecgHospitalList: res.data.pageData,
          totalRows: res.data.totalRows,
          params
        })
        if (rootState.EcgHospitalList.params.reset && !payload.first) {
          rootState.EcgHospitalList.params.reset()
        }
      }else{
        modelMsg(res.status, res.msg)
      }
    },
    async getTitleList() {
      const url = '/api/config/getTitles'
      const res = await request.get(url)

      if (res.status === 0) {
        this.updateState({
          titleList: res.data
        })
      }
    },
    async getPersGroupList() {
      const url = '/api/config/getPermsGroups'
      const res = await request.get(url)

      if (res.status === 0) {
        this.updateState({
          persGroup: res.data
        })
      }
    }
  })
}
