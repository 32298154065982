import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './permissionList.styl'

import { history } from '../../routers'
import { modelMsg } from '../../components/modelMethods'
// import { baseUrl } from '../../request'

import MyTable from '../../components/MyTable/myTable'
import {
  Select,
  Row,
  Col,
  Button,
  Input,
  Pagination,
  Cascader,
  Icon,
  Switch,
  Modal
} from 'antd'
import CommonModal from '../../components/CommonModal/commonModal'

import MyIcon from '../../components/myIcon'
import request from '../../request'
// import ChangePwdModal from './changePwdModal'

function getRoleTag(type) {
  switch (type) {
    case '系统管理员':
      return (
        <span style={{ borderColor: '#FF5500' }} className="role-tag">
          系统管理员
        </span>
      )
    case '机构管理员':
      return (
        <span style={{ borderColor: '#3D4AFF' }} className="role-tag">
          机构管理员
        </span>
      )
    case '报告医生':
      return <span className="role-tag">报告医生</span>
    default:
      return <span className="role-tag">-</span>
      break
  }
}

class PermissionList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUser: {},
      searched: false,
      changeAdminShow: false,
      selectedRows: [],
      selectedRowKeys: [],
      creatTime: [],
      show: false,
      roleName: '',
      gender: undefined,
      hasFundus: undefined,
      hasSurface: undefined,
      orgModelTitle: '新增权限组',
      searchRoleName: '',
      roleName: '',
      moduleName: '',
      system: '',
      systemType: '',
      remark: '',
      formOrgName: '',
      maxAge: '',
      minAge: '',
      currentPermissionId: '',
      roleNameList: [
        {
          label: '病案质控子系统',
          value: '病案质控子系统'
        },
        {
          label: '数据预警子系统',
          value: '数据预警子系统'
        },
        {
          label: '智能搜索子系统',
          value: '智能搜索子系统'
        }
      ],
      columns: [
        {
          title: '权限组名称',
          dataIndex: 'roleName'
        },
        {
          title: '所属系统',
          dataIndex: 'system',
          render: text => {
            switch (text) {
              case 1:
                return '病案质控子系统'
              case 2:
                return '数据预警子系统'
              case 3:
                return '智能搜索子系统'
              case -1:
                return '-'
              default:
                return '-'
                break
            }
          }
        },
        {
          title: '描述',
          dataIndex: 'remark'
        },
        {
          title: '权限组状态',
          dataIndex: 'state',
          render: (text, record) => {
            return (
              <Switch
                checked={text}
                onChange={checked => {
                  this.props.changePermissionState({
                    id: record.id,
                    state: !record.state
                  })
                }}
                disabled={record.system === -1}
              ></Switch>
            )
          }
        },
        {
          title: '操作',
          dataIndex: 'option',
          render: (text, record) => {
            return (
              <Row
                style={{
                  color: '#458FF7',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <span>
                  <a
                    onClick={() => this.editPermission(record)}
                    style={{
                      color: '#AB875F',
                      cursor: 'pointer',
                      marginRight: 28,
                      opacity: record.system === -1 ? 0.5 : 1
                    }}
                  >
                    编辑
                  </a>
                </span>
                <span>
                  <a
                    style={{
                      color: '#9A2B2B',
                      cursor: 'pointer',
                      opacity: record.system === -1 ? 0.5 : 1
                    }}
                    onClick={() => this.showDeleteRegister(record)}
                  >
                    删除
                  </a>
                </span>
              </Row>
            )
          }
        }
      ],
      inchargeName: []
    }
  }
  editPermission = record => {
    if (record.system === -1) return
    this.setState({
      changeAdminShow: true,
      currentPermissionId: record.id,
      orgModelTitle: '编辑权限组',
      roleName: record.roleName,
      moduleName: record.moduleIds ? record.moduleIds.split(',') : [],
      remark: record.remark
    })
  }
  componentDidMount() {
    let pathname = this.props.history.location.pathname
    let tabType
    if (pathname === '/warnPermission') {
      tabType = 2
    } else if (pathname === '/permission') {
      tabType = 1
    } else if (pathname === '/searchPermission') {
      tabType = 3
    }
    this.props.getPermissionList({
      system: tabType
    })
    const system =
      this.props.personal.userInfo.permission &&
      this.props.personal.userInfo.permission.system
    this.setState({
      system:
        system === 1
          ? '病案质控子系统'
          : system === 2
          ? '数据预警子系统'
          : system === 3
          ? '智能搜索子系统'
          : system === -1
          ? tabType === 1
            ? '病案质控子系统'
            : tabType === 2
            ? '数据预警子系统'
            : tabType === 3
            ? '智能搜索子系统'
            : ''
          : '',
      systemType: tabType
    })
    this.props.getModuleList({
      system: tabType
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let pathname = this.props.history.location.pathname
    let tabType
    if (pathname === '/warnPermission') {
      tabType = 2
    } else if (pathname === '/permission') {
      tabType = 1
    } else if (pathname === '/searchPermission') {
      tabType = 3
    }
    if (nextProps.personal.userInfo !== this.props.personal.userInfo) {
      const system =
        nextProps.personal.userInfo.permission &&
        nextProps.personal.userInfo.permission.system
      this.setState({
        system:
          system === 1
            ? '病案质控子系统'
            : system === 2
            ? '数据预警子系统'
            : system === 3
            ? '智能搜索子系统'
            : system === -1
            ? tabType === 1
              ? '病案质控子系统'
              : tabType === 2
              ? '数据预警子系统'
              : tabType === 3
              ? '智能搜索子系统'
              : ''
            : '',
        systemType: tabType
      })
      // this.props.getModuleList({
      //   system
      // })
    }
  }
  remarkOnBlur = () => {
    if (!this.state.remark) {
      console.log('error')
      this.setState({
        remarkErr: 2
      })
    } else {
      this.setState({
        remarkErr: 1
      })
    }
  }

  roleNameOnBlur = () => {
    if (!this.state.roleName) {
      console.log('error')
      this.setState({
        roleNameErr: 2
      })
    } else {
      this.setState({
        roleNameErr: 1
      })
    }
  }

  systemOnBlur = () => {
    if (!this.state.system) {
      console.log('error')
      this.setState({
        systemErr: 2
      })
    } else {
      this.setState({
        systemErr: 1
      })
    }
  }

  moduleNameOnBlur = () => {
    if (this.state.moduleName.length === 0) {
      console.log('error')
      this.setState({
        moduleNameErr: 2
      })
    } else {
      this.setState({
        moduleNameErr: 1
      })
    }
  }

  addPermission = () => {
    this.remarkOnBlur()
    this.moduleNameOnBlur()
    this.roleNameOnBlur()
    this.systemOnBlur()

    const { remark, roleName, moduleName, systemType } = this.state
    console.log(moduleName)
    if (remark && roleName && moduleName.length !== 0) {
      const payload = {
        roleName,
        moduleName: moduleName.join(','),
        remark,
        system: systemType
      }
      if (this.state.currentPermissionId) {
        payload.id = this.state.currentPermissionId
        this.props.editPermissionModal(payload)
      } else {
        this.props.addPermissionModal(payload)
      }
      this.setState({
        changeAdminShow: false
      })
      this.clearAllErr()
      this.clearAllValue()
    } else {
      console.log('error')
      return
    }
  }
  showDeleteRegister = record => {
    if (record.system === -1) return
    // console.log(id)
    var that = this
    this.CommonModal.show({
      title: '删除',
      msg: '确认进行删除吗？',
      modalType: 'confirm',
      isCancel: true,
      okFunc: that.deletePermission,
      okFuncParams: {
        id: record.id
      }
    })
  }
  deletePermission = payload => {
    // console.log(payload)
    this.props.deletePermission(payload)
  }
  changeStateClick = (status, organizationId, organizationName) => {
    let title
    let msg
    let state
    if (!status) {
      title = '停用账号'
      msg = (
        <span>
          确认 <span style={{ color: '#406168' }}>停用</span> {organizationName}
          机构，并停用所有账号吗？
        </span>
      )
      state = false
    } else {
      title = '启用账号'
      msg = (
        <span>
          确认 <span style={{ color: '#406168' }}>启用</span> {organizationName}
          机构，并启用所有账号吗？
        </span>
      )
      state = true
    }
    this.CommonModal.show({
      title,
      modalType: 'confirm',
      msg,
      okFunc: this.stateOkFunc,
      okFuncParams: {
        id: organizationId,
        state: !state ? 1 : 0
      }
    })
  }
  stateOkFunc = okFuncParams => {
    this.props.modifyCertificationState(okFuncParams)
  }

  changeState = (e, organizationId) => {
    console.log(e)
    console.log(organizationId)
    // this.props.
  }
  showUploadBox = (res, defaultTab) => {
    const that = this
    if (res.reportState === 7) {
      modelMsg(1, '此患者已出报告，无法更改图片')
      return
    }
    this.setState(
      {
        screeningId: res.id,
        leftFundus: res.leftFundus,
        rightFundus: res.rightFundus,
        defaultTab: defaultTab === 1 ? 1 : 2
      },
      function() {
        that.showImgBox(defaultTab)
      }
    )
  }
  showImgBox = defaultType => {
    console.log(defaultType)
    // this.cameraUpload.show(defaultType)
  }
  search = () => {
    this.props.getPermissionList()
  }

  onShowSizeChange = (current, pageSize) => {
    const params = {
      pageSize,
      page: current
    }
    this.props.getPermissionList(params)
  }
  handleReset = () => {
    this.props.getPermissionList({
      page: 1,
      pageSize: 10,
      roleName: ''
    })
    this.setState({
      searchRoleName: ''
    })
  }
  fundusChange = e => {
    this.setState({ hasFundus: e })
    this.props.getPermissionList({ page: 1, hasFundus: e })
  }
  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns]
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      }
      return { columns: nextColumns }
    })
  }
  resetSelect = () => {
    this.setState({
      selectedRows: [],
      selectedRowKeys: []
    })
  }
  changePage(page) {
    this.props.getPermissionList({ page })
  }
  goDetail = () => {
    if (!this.state.sid) {
      modelMsg(1, '请先输入患者编号')
      return
    }
    this.props.preCheck(this.state.sid)
  }
  // displayRender = (a) => {
  //   if(a.length === 0 || a.length === 4) {
  //     if(this.state.hospitalCode.length > 0 && this.state.hospitalCode !== '') {
  //       return a[a.length-1] || this.props.state.userInfo.hospitalText
  //     } else {
  //       return ''
  //     }
  //   } else {
  //     return ''
  //   }

  // }
  displayRender = (a, b) => {
    console.log(a)
    if (
      this.state.moduleName &&
      this.state.moduleName.length > a.join('-').length
    ) {
      return this.state.moduleName
    } else {
      return a.join('-')
    }
  }
  handleInchargeNameChange = val => {
    console.log(val)
    this.setState({
      inchargeName: val
    })
  }

  clearAllErr = () => {
    this.setState({
      systemErr: 1,
      moduleNameErr: 1,
      roleNameErr: 1,
      remarkErr: 1
    })
  }

  clearAllValue = () => {
    this.setState({
      // system: '',
      moduleName: '',
      roleName: '',
      remark: ''
    })
  }

  render() {
    const { state } = this.props
    const { userInfo } = this.props.personal
    const {
      columns,
      orgModelTitle,
      roleName,
      moduleName,
      menuList,
      system,
      remark,
      roleNameList,
      searchRoleName
    } = this.state

    return (
      <div className="permissionList-content">
        <CommonModal onRef={e => (this.CommonModal = e)} />
        {/* <ChangePwdModal onRef={ e => this.ChangePwdModal = e} /> */}

        <Modal
          title={orgModelTitle}
          className="orgModel"
          width="549px"
          visible={this.state.changeAdminShow}
          // onOk={this.handleOk}
          footer={null}
          onCancel={() => {
            this.setState({ changeAdminShow: false })
            this.clearAllErr()
          }}
        >
          <div
            className="input-data-content"
            style={{ position: 'relative', marginBottom: 22 }}
          >
            <div className="form-box-div">
              <label className="form-box-label">
                <label className="necessary"> * </label>
                所属系统
              </label>
              <Select
                // dropdownClassName="area-select"
                placeholder="请选择所属系统"
                style={{ width: 406 }}
                onChange={e =>
                  this.setState({
                    system: e
                  })
                }
                onBlur={this.systemOnBlur}
                value={system || undefined}
                allowClear
                // disabled={
                //   userInfo.permission && userInfo.permission.system !== -1
                // }
                disabled={true}
              >
                {roleNameList.map((i, index) => (
                  <Select.Option value={i.value} key={index}>
                    {i.label}
                  </Select.Option>
                ))}
              </Select>
              {this.state.systemErr === 2 ? (
                <p className="err-msg">
                  {' '}
                  <MyIcon type="iconcuowutishi" /> 请输入所属系统{' '}
                </p>
              ) : null}
            </div>
            <div className="form-box-div">
              <label className="form-box-label">
                <label className="necessary"> * </label>
                权限组名称
              </label>
              <Input
                value={roleName}
                maxLength={15}
                style={{ width: 406, textAlign: 'left' }}
                onChange={e => this.setState({ roleName: e.target.value })}
                onBlur={this.roleNameOnBlur}
                placeholder="请输入权限组名称"
              />
              {this.state.roleNameErr === 2 ? (
                <p className="err-msg">
                  {' '}
                  <MyIcon type="iconcuowutishi" /> 请输入权限组名称{' '}
                </p>
              ) : null}
            </div>
            <div className="form-box-div">
              <label className="form-box-label">
                <label className="necessary"> * </label>
                权限配置
              </label>
              <Select
                // dropdownClassName="area-select"
                mode="multiple"
                placeholder="请选择权限"
                style={{ width: 406 }}
                onChange={e => {
                  console.log(e)
                  this.setState({
                    moduleName: e
                  })
                }}
                value={moduleName || undefined}
                allowClear
                onBlur={this.moduleNameOnBlur}
              >
                {state.moduleList &&
                  state.moduleList.map((i, index) => (
                    <Select.Option value={i.id} key={index}>
                      {i.moduleName}
                    </Select.Option>
                  ))}
              </Select>
              {this.state.moduleNameErr === 2 ? (
                <p className="err-msg">
                  {' '}
                  <MyIcon type="iconcuowutishi" /> 请选择权限{' '}
                </p>
              ) : null}
            </div>
            <div className="form-box-div">
              <label className="form-box-label">
                <label className="necessary"> * </label>
                描述
              </label>
              <Input
                value={remark}
                maxLength={30}
                style={{ width: 406, textAlign: 'left' }}
                onChange={e => this.setState({ remark: e.target.value })}
                onBlur={this.remarkOnBlur}
                placeholder="请输入描述"
              />
              {this.state.remarkErr === 2 ? (
                <p className="err-msg">
                  {' '}
                  <MyIcon type="iconcuowutishi" /> 请输入描述{' '}
                </p>
              ) : null}
            </div>
          </div>
          <div style={{ textAlign: 'right', padding: '20px 18px 30px' }}>
            <Button
              onClick={this.addPermission}
              style={{ width: 100, background: '#8C775F' }}
              type="primary"
            >
              确定
            </Button>
          </div>
        </Modal>
        <Row>
          <Col className="title">权限管理</Col>
          <Col className="search-part">
            <Row>
              <Col
                className="ipt"
                span={21}
                style={{ display: 'flex', justifyContent: 'flex-start' }}
              >
                {/* <Cascader
                  changeOnSelect
                  allowClear={true}
                  displayRender={this.displayRender}
                  expandTrigger="click"
                  loadData={e => {
                    this.loadData(e)
                  }}
                  onChange={(e, v) => {
                    this.handleChange(e, v)
                  }}
                  onBlur={this.areaIdListOnBlur}
                  options={areaList}
                  value={this.state.areaIdList}
                  placeholder={this.state.organization ? '' : '所在区域'}
                  style={{ width: 322, marginRight: 20, textAlign: 'left' }}
                /> */}

                <Input.Search
                  style={{ width: 322 }}
                  enterButton
                  // onBlur={() => { this.props.getPermissionList({ page: 1, roleName }) }}
                  onChange={e => {
                    this.setState({
                      searchRoleName: e.target.value
                    })
                  }}
                  onSearch={() =>
                    this.props.getPermissionList({
                      page: 1,
                      roleName: searchRoleName
                    })
                  }
                  placeholder="请输入权限组名称"
                  value={searchRoleName}
                ></Input.Search>

                {/* <Input.Search
                  enterButton={<Icon type="smile" />}
                  placeholder="input search text"
                  onSearch={value => console.log(value)}
                  style={{ width: 200 }}
                /> */}
              </Col>
              <Col span={3} style={{ textAlign: 'right' }}>
                <Button
                  ghost
                  onClick={this.handleReset}
                  style={{ width: 80 }}
                  type="primary"
                  className="clear"
                >
                  清空
                </Button>
              </Col>

              {/* <Col span={3} style={{textAlign: 'right'}}>
                <SearchButton
                  onClick={() => { this.setState({ show: !show }) }}
                  show={show}
                />
              </Col> */}
            </Row>
          </Col>

          <Col className="title-part" style={{ marginTop: 20 }}>
            <label className="title-label" htmlFor="">
              权限组列表
            </label>

            <div>
              <Button
                onClick={() => {
                  this.setState({
                    changeAdminShow: true,
                    currentPermissionId: ''
                  })
                  this.clearAllErr()
                  this.clearAllValue()
                }}
                type="primary"
                className="add-btn"
                style={{ background: '#8C775F' }}
              >
                新增
              </Button>
            </div>
          </Col>
          <Col>
            <MyTable
              columns={columns}
              handleResize={this.handleResize}
              list={state.permissionList}
              rowKey={'id'}
            />
            <Row style={{ padding: '20px', background: '#fff' }}>
              {state.permissionList.length > 0 ? (
                <Pagination
                  current={this.props.state.params.page}
                  defaultCurrent={1}
                  onChange={page => {
                    this.changePage(page)
                  }}
                  onShowSizeChange={this.onShowSizeChange}
                  showQuickJumper
                  showSizeChanger
                  showTotal={e => `共 ${e} 条`}
                  style={{ float: 'right' }}
                  total={state.totalRows}
                />
              ) : null}
            </Row>
          </Col>
        </Row>
      </div>
    )
  }
}

const mapState = state => {
  return {
    state: state.PermissionList,
    user: state.UserControll,
    personal: state.Personal
  }
}

const mapDispatch = dispatch => {
  const { PermissionList } = dispatch
  const {
    getPermissionList,
    getModuleList,
    addPermissionModal,
    editPermissionModal,
    deletePermission,
    changePermissionState
  } = PermissionList
  return {
    getPermissionList: payload => getPermissionList(payload),
    getModuleList: payload => getModuleList(payload),
    addPermissionModal: payload => addPermissionModal(payload),
    editPermissionModal: payload => editPermissionModal(payload),
    deletePermission: payload => deletePermission(payload),
    changePermissionState: payload => changePermissionState(payload)
  }
}

export default withRouter(connect(mapState, mapDispatch)(PermissionList))
