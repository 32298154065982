import request from '../../request'
import axios from 'axios'
import { baseUrl } from '../../request'
import { push } from 'react-router-redux'
import { modelMsg } from '../../components/modelMethods'
const md5 = require('js-md5')

export default {
  state: {
    name: 'MessageCenterList',
    messageCenterList: [],
    totalRows: 0,
    params: {
      page: 1,
      pageSize: 10
    },
    logParams: {
      page: 1,
      pageSize: 10
    },
    logTotalRows: 0,
    logList: [],
    messageDetail: {}
  }, // initial state
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    }
  },
  effects: dispatch => ({
    async getMessageList(payload, rootState) {
      const url = '/api/message/page'
      const params = { ...rootState.MessageCenterList.params, ...payload }
      const res = await request.get(url, { params })

      if (res.status === 0) {
        this.updateState({
          messageCenterList: res.data.pageData,
          totalRows: res.data.totalRows,
          params
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async readMessage(payload) {
      const url = `/api/message/read/${payload.id}`
      const res = await request.post(url)
      if (res.status === 0) {
        this.getMessageList()
        modelMsg(0, '已读成功')
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async removeMessage(payload) {
      const url = `/api/message/remove/${payload.id}`
      const res = await request.post(url)
      if (res.status === 0) {
        this.getMessageList()
        modelMsg(0, '删除成功')
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async readAllMessage() {
      const url = `/api/message/allRead`
      const res = await request.post(url)
      if (res.status === 0) {
        this.getMessageList()
        modelMsg(0, '全部已读成功')
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getMessageDetail(payload) {
      const url = `/api/message/detail`
      const res = await request.get(url, { params: { ...payload } })
      if (res.status === 0) {
        this.updateState({
          messageDetail: res.data
        })
        this.readMessage({ id: payload.id })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getLogPageList(payload, rootState) {
      const url = `/api/log/page`
      const logParams = { ...rootState.MessageCenterList.logParams, ...payload }
      const res = await request.get(url, { params: logParams })

      if (res.status === 0) {
        this.updateState({
          logList: res.data.pageData,
          logTotalRows: res.data.totalRows,
          logParams
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    }
  })
}
