import request from '../../request'
import axios from 'axios'
import { baseUrl } from '../../request'
import { push } from 'react-router-redux'
import { modelMsg } from '../../components/modelMethods'
import { message } from 'antd';
const md5 = require('js-md5')

export default {
  state: {
    name: 'selectionOverview',
    params: {
      page: 1,
      pageSize: 10
    },
    totalRows: 2,
    totalCase: 2,
    reviewExpertTotal: 0,
    projectDetail: {},
    projectData: {},
    caseData: {},
    scoreData: {},
    caseList: [],
    projectList: [],
    allOrgList: [],
    reviewExpertList: [],
    selectedList: [],
  }, // initial state
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    },
    closeAlert(state) {
      return { ...state, ...{ status: -1 } }
    }
  },
  effects: (dispatch) => ({
    async getProjectPage(payload) {
      const params = payload
      const url = `/api/project/page`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ projectList: res.data.pageData, totalRows: res.data.totalRows })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async getAllOrg(payload) {
      const params = payload
      const url = `/api/project/getAllOrg`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ allOrgList: res.data })
      } else {
        modelMsg(1, res.msg)
      }
      return res.status
    },
    async getReviewExpertList(payload) {
      const url = `/api/case/reviewExpertList`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        const reviewExpertList = res.data.map(item => {
          return {
            title: item.realName,
            key: item.id,
            disabled: item.checked
          }
        })

        const selectedList = res.data.filter(f => f.checked).map(item => item.id)
        this.updateState({ reviewExpertList, selectedList })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getCasePage(payload) {
      const params = payload
      const url = `/api/case/page`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        // res.data.pageData.map(d => d.status = 0)
        this.updateState({ caseList: res.data.pageData, totalCase: res.data.totalRows })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async projectDetail(payload) {
      const params = payload
      const url = `/api/project/detail`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        res.data.relatedHospitalName = res.data.relatedHospitalName.replaceAll(',', '、')
        this.updateState({ projectDetail: res.data })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async checkProjectScore(payload) {
      const params = payload
      const url = `/api/project/checkProjectScore`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        // this.updateState({ projectDetail: res.data })
      } else {
        modelMsg(1, res.msg)
      }
      return res.data
    },
    async projectStatistics(payload) {
      const params = payload
      const url = `/api/project/projectStatistics`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ projectData: res.data })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async caseStatistics(payload) {
      const params = payload
      const url = `/api/case/caseStatistics`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ caseData: res.data })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async reviewExpertList(payload) {
      const params = payload
      const url = `/api/case/reviewExpertList`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ reviewExpertTotal: res.data.length })
      } else {
        modelMsg(1, res.msg)
      }
    },
    async scoreStatistics(payload) {
      const params = payload
      const url = `/api/score/scoreStatistics`
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({ scoreData: res.data })
      } else {
        modelMsg(1, res.msg)
      }
      return res.data
    },
    async scoreAssign(payload) {
      const params = payload
      const url = `/api/score/assign`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '指派成功')
      } else {
        modelMsg(res.status, res.msg)
      }
      return res.status
    },
    async addProject(payload) {
      const params = payload
      const url = `/api/project/add`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
      } else {
        modelMsg(res.status, res.msg)
      }
      return res
    },
    async endProject(payload) {
      const params = payload
      const url = `/api/project/end`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
      } else {
        modelMsg(res.status, res.msg)
      }
      return res.status
    },
    async editProject(payload) {
      const params = payload
      const url = `/api/project/edit`
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '编辑成功')
      } else {
        modelMsg(res.status, res.msg)
      }
      return res
    },
    async preliminaryReview(payload) {
      const params = payload
      const url = `/api/case/preliminaryReview/${params.caseId}`
      const res = await request.post(url, params)
      // if (res.status === 0) {
      //   modelMsg(res.status, '编辑成功')
      // } else {
      modelMsg(res.status, res.msg)
      // }
      return res
    },
  })
}