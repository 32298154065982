import request from '../../request'
import axios from 'axios'
import { baseUrl } from '../../request'
import { push } from 'react-router-redux'
import { modelMsg } from '../../components/modelMethods'
import { encryptData } from '../../utils/sm2Encrypt'
const md5 = require('js-md5')

let qualityNames = [
  { name: '患者管理', path: '/patientControl' },
  { name: '质控概况', path: '/qualityOverview' },
  { name: '质控管理', path: '/qualityControl' },
  { name: '案例概况', path: '/selectionOverview' },
  { name: '专家评选', path: '/expertSelection' },
  { name: '案例上报', path: '/caseReport' },
  { name: '综合配置', path: '/qualityConfigure' },
  { name: '用户管理', path: '/ecgUserList' },
  { name: '权限管理', path: '/permission' },
  { name: '组织管理', path: '/ecgHospitalList' }
]

let searchList = [
  { name: '搜索首页', path: '/search' },
  { name: '搜索概况', path: '/searchOverview' },
  { name: '自由看板', path: '/searchFreePanel' },
  { name: '搜索收藏', path: '/searchCollect' },
  { name: '搜索历史', path: '/searchHistory' },
  // { name: '知识图谱', path: '/searchHistory' },
  { name: '用户管理', path: '/searchUserList' },
  { name: '权限管理', path: '/searchPermission' },
  { name: '综合配置', path: '/searchConfigure' }
]

let bigScreenList = [
  { name: '预警管理', path: '/warnList' },
  { name: '规则管理', path: '/ruleManage' },
  { name: '数据大屏', path: '/bigScreen' },
  { name: '用户管理', path: '/warnUserList' },
  { name: '权限管理', path: '/warnPermission' }
]

export default {
  state: {
    name: 'logins',
    count: 0,
    isError: false,
    loginStatus: '',
    errMsg: '',
    switchh: true,
    status: -1,
    // message: '',
    SmsCode: false,
    switchKey: 'login',
    captchaKey: '',
    captchaImg: '',
    pwdErr: false,
    preLoginInfo: {}
  }, // initial state
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    },
    closeAlert(state) {
      return { ...state, ...{ status: -1 } }
    }
  },
  effects: dispatch => ({
    async getCaptchaKey() {
      const url = `${baseUrl}/api/common/captcha`
      axios
        .get(url)
        .then(res => {
          if (res.data.status === 0) {
            this.updateState({
              captchaKey: res.data.data.captchaKey,
              captchaImg: res.data.data.img
            })
          }
        })
        .catch(() => {})
    },

    async login(payload) {
      let url
      this.updateState({
        pwdErr: false
      })
      const type = payload.type
      const checked = payload.checked
      switch (payload.type) {
        case 'login':
          url = '/api/user/login'
          break
        case 'loginCode':
          url = `/auth/loginByCode?phone=${payload.phone}&code=${payload.code}`
          break
        default:
          url = '/api/user/login'
          break
      }
      let params
      if (type === 'loginCode') {
        params = { ...payload }
      } else {
        params = {
          ...payload,
          ...{ password: md5(payload.password + 'truthai^&%$') }
        }
      }
      const cb = params.cb
      delete params.cb
      const res = await request.post(url, params)

      if (res.status === 0) {
        localStorage.token = res.data.token

        if (res.data.needActiveOrganization) {
          setTimeout(() => {
            dispatch(push(`/activeSerial/${res.data.organizationId}`))
          })
        } else {
          modelMsg(res.status, '登录成功')

          setTimeout(() => {
            dispatch(push('/registerList'))
          })
        }
      } else {
        switch (res.msg) {
          case '图形验证码不正确':
            modelMsg(res.status, '图形验证码不正确')
            break
          case '账号或密码错误':
            // cb({
            //   msg: `账号或密码错误`,
            //   title: '新增成功',
            //   titleType: 'danger'
            // })
            this.updateState({
              pwdErr: true
            })
            break
          case '登录操作错误次数达到了系统限制，请稍后再试！':
            cb({
              msg: `登录操作错误次数达到了系统限制，请稍后再试！`,
              title: '账号锁定',
              titleType: 'danger'
            })
            break
          case '登录操作错误次数达到了系统限制，请30分钟后再试！':
            cb({
              msg: `登录操作错误次数达到了系统限制，请30分钟后再试！`,
              title: '账号锁定',
              titleType: 'danger'
            })
            break
          case '您的账号已被管理员停用，如有问题请联系管理员！':
            cb({
              msg: `您的账号已被管理员停用，如有问题请联系管理员！`,
              title: '账号停用',
              titleType: 'danger'
            })
            break
          default:
            break
        }
      }
      // modelMsg(res.status, res.msg)
    },
    async getCode(payload, rootState) {
      let url
      if (!payload.captchaCode) {
        this.updateState({
          status: 1,
          message: '请输入图形验证码'
        })
        payload.cb()
        return false
      }
      switch (payload.type) {
        case 'login':
          url = `${baseUrl}/auth/sendLoginCode`
          break
        case 'signUp':
          url = `${baseUrl}/auth/sendRegisterCode`
          break
        case 'forget':
          url = `${baseUrl}/auth/sendResetCode`
          break
        default:
          url = '/auth/sendResetCode'
          break
      }
      const cb = payload.cb
      delete payload.type
      delete payload.cb
      const params = {
        ...payload,
        ...{ captchaKey: rootState.Login.captchaKey }
      }

      axios
        .get(url, { params })
        .then(res => {
          if (res.data.status === 0) {
            // this.updateState({ SmsCode: true })
          } else {
            cb()
            this.getCaptchaKey()
            this.updateState({
              // SmsCode: false,
              status: res.data.status,
              message: res.data.msg
            })
          }
        })
        .catch(() => {})
    },
    async signUp(payload) {
      const url = `/auth/register?phone=${payload.phone}&password=${md5(
        payload.password + 'truthai^&%$'
      )}&code=${payload.smsCode}`
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        this.updateState({
          status: res.status,
          message: '注册成功，请登录',
          switchKey: 'login'
        })
      } else {
        this.updateState({
          status: res.status,
          message: res.msg
        })
      }
    },
    async resetPwd(payload) {
      const url = `/auth/resetPwd?phone=${payload.phone}&password=${md5(
        payload.password + 'truthai^&%$'
      )}&code=${payload.smsCode}`
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        this.updateState({
          status: res.status,
          message: '重置密码成功，请登录',
          switchKey: 'login'
        })
      } else {
        this.updateState({
          status: res.status,
          message: res.msg
        })
      }
    },

    async refreshToken() {
      const url = '/auth/refreshToken'
      const params = {
        refreshToken: localStorage.refreshToken || 123
      }
      const res = await request.post(url, params)
      if (res.status === 0) {
        localStorage.token = res.data.token
        localStorage.refreshToken = res.data.refreshToken
        localStorage.RTEI = res.data.refreshTokenExpiresIn
        localStorage.TEI = res.data.tokenExpiresIn
        localStorage.ST = new Date().valueOf()

        window.isRefreshing = false
      }
      // modelMsg(res.status, res.msg)
      this.updateState({
        errMsg: res.msg,
        loginStatus: res.status
      })

      return res.data.token || localStorage.token
    },
    async logins(params) {
      const url = '/api/user/preLogin'
      // params.password = md5(params.password + 'truthai^&%$')
      params.account = encryptData(params.account)
      params.password = encryptData(params.password)
      const res = await request.post(url, params)
      if (res.status === 0) {
        // localStorage.token = res.data.token
        modelMsg(res.status, '登录成功')
        setTimeout(() => {
          dispatch(push('/verification'))
        })
        // this.getCurrentUser()
        this.updateState({
          isError: false,
          preLoginInfo: res.data
        })
        return
      }
      if (res.msg === '账号或密码错误') {
        this.updateState({
          isError: true
        })
        return
      }
      modelMsg(res.status, res.msg)
      this.updateState({
        isError: false
      })
    },
    async getNumCode(params) {
      const url = '/api/user/sendVerificationCode'
      const res = await request.get(url, { params })
      if (res.status === 0) {
        modelMsg(res.status, '验证码发送成功')
      } else {
        modelMsg(res.status, '验证码发送失败')
      }
    },
    async submit(params) {
      const url = '/api/user/login'
      const res = await request.post(url, params)
      if (res.status === 0) {
        localStorage.token = res.data.token
        modelMsg(res.status, '验证成功')
        setTimeout(() => {
          dispatch(push('/welcome'))
        })
      } else {
        modelMsg(res.status, '验证失败')
      }
    },
    async getCurrentUser() {
      const url = '/api/user/getCurrentUserInfo'
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          userInfo: res.data
        })
        let system = res.data.system
        let moduleNames = res.data.permission.moduleNames

        // if (system === 1) {
        //   const res = qualityNames.filter(item => {
        //     return moduleNames.includes(item.name)
        //   })
        //   console.log(res)
        //   setTimeout(() => {
        //     dispatch(push(res[0].path))
        //   })
        // } else if (system === 2) {
        //   const res = bigScreenList.filter(item => {
        //     return moduleNames.includes(item.name)
        //   })
        //   console.log(res)
        //   setTimeout(() => {
        //     dispatch(push(res[0].path))
        //   })
        // } else if (system === 3) {
        //   const res = searchList.filter(item => {
        //     return moduleNames.includes(item.name)
        //   })
        //   console.log(res)
        //   setTimeout(() => {
        //     dispatch(push(res[0].path))
        //   })
        // } else {
        //   setTimeout(() => {
        //     dispatch(push('/welcome'))
        //   })
        // }
        setTimeout(() => {
          dispatch(push('/welcome'))
        })
      } else {
        dispatch(push('/login'))
      }
    }
  })
}
