import request from '../../request'
import { modelMsg } from '../../components/modelMethods'
import axios from 'axios'

export default {
  state: {
    name: 'Search',
    hotSearchList: [],
    boardList: [],
    searchBoardList: [],
    albumList: [],
    favoriteList: [],
    searchResult: {},
    echartDescribe: {},
    themeConfig: {},
    exportConfig: {},
    isSearchDone: false,
    isNoData: false
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
      // return Object.assign({}, state, payload)
    }
  },
  effects: () => ({
    async setUserAuth(params) {
      const url = `/api/user/auth?account=${params}`
      const res = await request.post(url)
      if (res.status === 0) {
        localStorage.token = res.data.token
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${res.data.token}`
        return
      }
      modelMsg(res.status, res.msg)
    },
    async getHotSearchList() {
      const url = '/api/search/getHotSearchList'
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          hotSearchList: res.data
          // hotSearchList: [
          //   '2023年7月最常见得十种中医症候',
          //   '2023年常山县中医医院就诊数量个人物难玩',
          //   '2023年大暑比小暑期间就诊人数个人物难玩',
          //   '2023年7月最常见的十种中医证候',
          //   '2023年6-8月就诊人数最多的十个人物难玩',
          //   '2023年8月最常见的十种中医证候'
          // ]
        })
      }
    },
    async getBoardList(payload) {
      console.log(payload)
      const url = '/api/board/list'
      const params = { ...payload }
      delete params.isSearch
      const res = await request.get(url, { params })
      if (res.status === 0) {
        // if (payload.isSearch) {
        //   this.updateState({
        //     searchBoardList: res.data
        //   })
        // } else {
        //   this.updateState({
        //     boardList: res.data
        //   })
        this.updateState({
          boardList: res.data
        })
        // }
      }
    },
    async search(payload) {
      const url = '/api/search'
      const params = payload
      this.updateState({
        searchResult: {},
        echartDescribe: {}
      })
      const res = await request.post(url, params)
      if (res.status === 0) {
        // this.getSearchResult({ id: res.data })
      } else {
        modelMsg(res.status, res.msg)
      }
      return res
    },
    async getSearchResult(payload) {
      const params = payload
      const url = '/api/search/getSearchResult'
      const res = await request.get(url, { params })
      this.updateState({
        isSearchDone: false
      })
      if (res.status === 0) {
        if (res.data) {
          if(res.data.state === 1){
            this.updateState({
              searchResult: res.data,
              echartDescribe: JSON.parse(res.data.echartDescribe)[0],
            })
          }
          this.updateState({
            isSearchDone: true
          })
        }
        return res
      }
      return res.data
    },
    async addFolder(payload) {
      const url = '/api/board/addFolder'
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        this.getBoardList({ isSearch: false })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async editFolder(payload) {
      const url = `/api/board/modifyFolder/${payload.folderId}`
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        this.getBoardList({ isSearch: false })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async deleteFolder(payload) {
      const url = `/api/board/removeFolder/${payload.folderId}`
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        this.getBoardList({ isSearch: false })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async addBoard(payload) {
      const url = '/api/board/addBoard'
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        this.getBoardList({ isSearch: false })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async editBoard(payload) {
      const url = `/api/board/modifyBoard/${payload.boardId}`
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        this.getBoardList({ isSearch: false })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async deleteBoard(payload) {
      const url = `/api/board/removeBoard/${payload.boardId}`
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        this.getBoardList({ isSearch: false })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async addAlbum(payload) {
      const url = '/api/search/addAlbum'
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        this.getAlbumList()
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getAlbumList() {
      const url = '/api/search/albumList'
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          albumList: res.data.map(item => {
            return { ...item, checked: false }
          })
        })
      }
    },
    async getFavoriteList() {
      const url = '/api/search/favoriteList'
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          favoriteList: res.data
        })
      }
    },
    async collect(payload) {
      const url = '/api/search/collect'
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, '收藏成功')
        this.getAlbumList()
        return Promise.resolve(res.msg)
      } else {
        modelMsg(res.status, res.msg)
        return Promise.reject(res.msg)
      }
    },
    async addTable(payload) {
      const url = '/api/board/addTable'
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
        this.getTablesByBoardId({ boardId: payload.boardId })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async editTable(payload) {
      const url = `/api/board/modifyTable/${payload.tableId}`
      const params = payload

      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
        this.getTablesByBoardId({ boardId: payload.boardId })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getTablesByBoardId(payload) {
      const url = `/api/board/getTables/${payload.boardId}`
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          boardTables: res.data
        })
      }
    },
    async removeTableByBoardId(payload) {
      const url = `/api/board/removeTable/${payload.tableId}`
      const res = await request.post(url, payload)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    //导出图片
    async exportTableImage(payload) {
      const url = '/api/board/exportTableImage'
      const res = await request.post(url, payload)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    //导出数据
    async exportTableData(payload) {
      const url = `/api/board/exportTableData/${payload.tableId}`
      const res = await request.post(url, payload)
    },
    async exportWord(payload) {
      const url = `/api/board/exportWord`
      const res = await request.post(url, payload)
    },
    async exportPdf(payload) {
      const url = `/api/board/exportPdf`
      const res = await request.post(url, payload)
    },
    async searchRefresh(payload) {
      const url = `/api/search/refresh/${payload.historyId}`
      const res = await request.post(url, payload)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getThemeConfig(payload) {
      const url = `/api/search/getThemeConfig`
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          themeConfig: res.data
        })
      }
    },
    async saveThemeConfig(payload) {
      const url = `/api/search/saveThemeConfig`
      const res = await request.post(url, payload)
      if (res.status === 0) {
        this.getThemeConfig()
      }
      modelMsg(res.status, res.msg)
    },
    async getExportConfig(payload) {
      const url = `/api/search/getExportConfig`
      const res = await request.get(url)
      if (res.status === 0) {
        this.updateState({
          exportConfig: res.data
        })
      }
    },
    async saveExportConfig(payload) {
      const url = `/api/search/saveExportConfig`
      const res = await request.post(url, payload)
      if (res.status === 0) {
        this.getExportConfig()
      }
      modelMsg(res.status, res.msg)
    },
  })
}
