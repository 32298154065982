import request from '../../request'
import { modelMsg } from '../../components/modelMethods'

export default {
  state: {
    name: 'QualityControl',
    params: {
      page: 1,
      pageSize: 10
    },
    qualityList: [],
    totalRows: 1,
    qualityDetail: {},
    patientInfo: {},
    prescriptionsList: [],
    fullResult: {},
    chufangFullResult: {},
    studyMsgConfig: '',
    medicalSmyConfig: '',
    preliminaryRvwConfig: '',
    caseRptConfig: '',
    logOptConfig: '',
    reviewLevel: [],
    bingliFullResult: {}
  },
  reducers: {
    updateState(state, payload) {
      return { ...state, ...payload }
    }
  },
  effects: dispatch => ({
    async getQualityList(payload = {}, rootState) {
      const url = '/api/quality/prescriptionQualityPage'
      const params = { ...rootState.QualityControl.params, ...payload }
      const res = await request.get(url, { params })

      if (res.status === 0) {
        this.updateState({
          qualityList: res.data.pageData,
          totalRows: res.data.totalRows,
          params
        })
      }
    },
    async getQualityDetail(payload = {}, rootState) {
      const url = `/api/quality/prescriptionQualityDetail/${payload.id}`
      const res = await request.get(url)
      console.log(res.data)
      if (res.status === 0) {
        this.updateState({
          qualityDetail: res.data,
          fullResult: res.data.fullResult ? JSON.parse(res.data.fullResult) : {}
        })
        if (res.data.fullResult && JSON.parse(res.data.fullResult)) {
          let newFillResult = { ...JSON.parse(res.data.fullResult) }
          let chufangFullResult = {}
          let bingliFullResult = {}
          Object.keys(newFillResult).forEach(key => {
            if (key !== 'qualityGrade' && key !== 'qualityScore') {
              chufangFullResult[key] = newFillResult[key].filter(item => {
                return item.featureType === 2 || item.featureType === 3
              })
              bingliFullResult[key] = newFillResult[key].filter(item => {
                return item.featureType === 1
              })
            }
          })
          this.updateState({
            chufangFullResult,
            bingliFullResult
          })
        }
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getPrescriptionsList(payload = {}, rootState) {
      const url = `/api/quality/prescriptions/${payload.patientId}`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          prescriptionsList: res.data
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getStudyMessageConfig(payload) {
      const url = `/api/config/getStudyMessageConfig`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          studyMsgConfig: res.data.configValue
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async saveStudyMessageConfig(payload) {
      const url = `/api/config/saveStudyMessageConfig`
      const params = payload
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
        await this.getStudyMessageConfig()
      } else {
        modelMsg(res.status, res.msg)
      }
      return res.status
    },
    async saveMedicalSummaryConfig(payload) {
      const url = `/api/config/saveMedicalSummaryConfig`
      const params = payload
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
        await this.getMedicalSummaryConfig()
      } else {
        modelMsg(res.status, res.msg)
      }
      return res.status
    },
    async getMedicalSummaryConfig(payload) {
      const url = `/api/config/getMedicalSummaryConfig`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          medicalSmyConfig: res.data.configValue
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async savePreliminaryReviewConfig(payload) {
      const url = `/api/config/savePreliminaryReviewConfig`
      const params = payload
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
        await this.getPreliminaryReviewConfig()
      } else {
        modelMsg(res.status, res.msg)
      }
      return res.status
    },
    async getPreliminaryReviewConfig(payload) {
      const url = `/api/config/getPreliminaryReviewConfig`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          preliminaryRvwConfig: res.data.configValue
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async saveCaseReportingConfig(payload) {
      const url = `/api/config/saveCaseReportingConfig`
      const params = payload
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
        await this.getCaseReportingConfig()
      } else {
        modelMsg(res.status, res.msg)
      }
      return res.status
    },
    async getCaseReportingConfig(payload) {
      const url = `/api/config/getCaseReportingConfig`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          caseRptConfig: res.data.configValue
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async saveLogOutputConfig(payload) {
      const url = `/api/config/saveLogOutputConfig`
      const params = payload
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
        await this.getLogOutputConfig()
      } else {
        modelMsg(res.status, res.msg)
      }
      return res.status
    },
    async getLogOutputConfig(payload) {
      const url = `/api/config/getLogOutputConfig`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          logOptConfig: res.data.configValue
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async saveReviewLevelConfig(payload) {
      const url = `/api/config/saveReviewLevelConfig`
      const params = payload
      const res = await request.post(url, params)
      if (res.status === 0) {
        modelMsg(res.status, res.msg)
        await this.getReviewLevelConfig()
      } else {
        modelMsg(res.status, res.msg)
      }
    },
    async getReviewLevelConfig(payload) {
      const url = `/api/config/getReviewLevelConfig`
      const params = payload
      const res = await request.get(url, { params })
      if (res.status === 0) {
        this.updateState({
          reviewLevel: res.data,
        })
      } else {
        modelMsg(res.status, res.msg)
      }
    },
  })
}
