import App from './views/App/model'
import Layouts from './views/Layouts/model'
import Logins from './views/Logins/model'

import Permission from './views/Permission/model'
import RoleControll from './views/RoleControll/model'

import Index from './views/Index/model'
import Personal from './views/Personal/model'

import EcgUserList from './views/EcgUserList/model'
import RegisterList from './views/RegisterList/model'

import EcgHospitalList from './views/EcgHospitalList/model'

import ReportDetail from './views/ReportDetail/model'
import QualityOverview from './views/QualityOverview/model'
import SelectionOverview from './views/SelectionOverview/model'
import PatientControl from './views/PatientControl/model'

import QualityControl from './views/QualityControl/model'
import CaseReport from './views/CaseReport/model'

import ExpertSelection from './views/ExpertSelection/model'
import Warning from './views/Warning/model'

import Search from './views/Search/model'

import PermissionList from './views/PermissionList/model'
import MessageCenterList from './views/MessageCenter/model'

// 健康筛查 -------------------------------------

/* 活动模块 active */
const models = {
  Index,
  App,
  Personal,
  Layouts,
  Permission,
  Logins,
  RoleControll,
  EcgUserList,
  RegisterList,
  EcgHospitalList,
  ReportDetail,
  QualityOverview,
  SelectionOverview,
  QualityControl,
  CaseReport,
  PatientControl,
  ExpertSelection,
  Warning,
  Search,
  PermissionList,
  MessageCenterList
}

export default models
