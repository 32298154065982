import React, { Component } from 'react'
import { Table } from 'antd'
import { Resizable } from 'react-resizable'
import './myTable.styl'
const ResizeableTitle = props => {
  const { onResize, width, ...restProps } = props

  if (!width) {
    return <th {...restProps} />
  }

  return (
    // <Resizable width={width} height={0} onResize={onResize}>
    //   <th {...restProps} />
    // </Resizable>
    <Resizable width={width} height={0} onResize={null}>
      <th {...restProps} />
    </Resizable>
  )
}
class MyTable extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const columns = this.props.columns.map((col, index) => ({
      ...{ className: col.dataIndex, align: 'center', ...col },
      onHeaderCell: column => ({
        width: column.width,
        onResize: this.props.handleResize(index),
        onMouseEnter: event => {
          document.querySelectorAll(`.${column.className}`).forEach(v => {
            v.style.borderLeft = '1px solid #E8EBF4'
            v.style.borderRight = '1px solid #E8EBF4'
          })
        },
        onMouseLeave: event => {
          document.querySelectorAll(`.${column.className}`).forEach(v => {
            v.style.borderLeft = '0'
            v.style.borderRight = '0'
          })
        }
      })
    }))
    const components = {
      header: {
        cell: ResizeableTitle
      }
    }
    return (
      <div className="myTable">
        <Table
          rowKey={record => record[this.props.rowKey]}
          rowSelection={this.props.rowSelection || null}
          pagination={false}
          onRow={this.props.onRow}
          locale={{
            emptyText: (
              <div style={{ textAlign: 'center' }}>
                <img
                  alt="nodata"
                  src={require('../../assets/images/huanze-nodata.png')}
                />
                <p>{'暂无数据~'}</p>
              </div>
            )
          }}
          page
          components={components}
          columns={columns}
          dataSource={this.props.list}
          onChange={this.props.onChange}
          rowClassName={this.props.rowClassName}
        />
      </div>
    )
  }
}

export default MyTable
