import React, { Component, lazy, Suspense } from 'react'
import { ConnectedRouter } from 'react-router-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
// import createHistory from 'history/createBrowserHistory'

import Pc, { Mobile } from './mode'
const createHistory = require("history").createBrowserHistory

const ReportDetail = lazy(() => import('./views/ReportDetail/index'))
const PulseReport = lazy(() => import('./views/ReportDetail/pulseReport'))
const TongueReport = lazy(() => import('./views/ReportDetail/tongueReport'))
const FaceReport = lazy(() => import('./views/ReportDetail/faceReport'))
const PrescriptReport = lazy(() => import('./views/ReportDetail/prescriptReport'))

const Login = lazy(() => import('./views/Logins/logins'))
const Index = lazy(() => import('./views/Index/index'))

const Permission = lazy(() => import('./views/Permission/permission'))

const PermissionList = lazy(() => import('./views/PermissionList/permissionList'))
const SearchPermission = lazy(() => import('./views/Search/permissionList'))
const MessageCenter = lazy(() => import('./views/MessageCenter/messageCenterList'))
const RoleControll = lazy(() => import('./views/RoleControll/roleControll'))

const Personal = lazy(() => import('./views/Personal/personal'))

// const DataDisplay = lazy(() => import('./views/DataDisplay/dataDisplay'))

const EcgUserList = lazy(() => import('./views/EcgUserList/ecgUserList'))
const SearchUserList = lazy(() => import('./views/Search/ecgUserList'))
const AddEcgUser = lazy(() => import('./views/EcgUserList/addEcgUser'))

const EcgHospitalList = lazy(() => import('./views/EcgHospitalList/ecgHospitalList'))
const AddEcgHospital = lazy(() => import('./views/EcgHospitalList/addEcgHospital'))

const AddRegister = lazy(() => import('./views/RegisterList/addRegister'))
const RegisterList = lazy(() => import('./views/RegisterList/registerList'))
const RegisterDetail = lazy(() => import('./views/RegisterList/registerDetail'))

const QualityOverview = lazy(() => import('./views/QualityOverview/qualityOverview'))
const QualityControl = lazy(() => import('./views/QualityControl/qualityControl'))
const QualityControlDetail = lazy(() => import('./views/QualityControl/qualityControlDetail/qualityControlDetail'))
const QualityConfigure = lazy(() => import('./views/QualityControl/qualityConfigure'))

const CaseDetail = lazy(() => import('./views/CaseReport/caseDetail'))
const ExpertSelectionDetail = lazy(() => import('./views/ExpertSelection/expertSelectionDetail'))
const CaseReport = lazy(() => import('./views/CaseReport/caseReport'))
const SelectionOverview = lazy(() => import('./views/SelectionOverview/selectionOverview'))
const ProjectDetail = lazy(() => import('./views/SelectionOverview/projectDetail'))
const PatientControl = lazy(() => import('./views/PatientControl/patientControl'))
const PatientDetail = lazy(() => import('./views/PatientControl/patientDetail'))
const ExpertSelection = lazy(() => import('./views/ExpertSelection/expertSelectionList'))
const WarnList = lazy(() => import('./views/Warning/warnList'))
const RuleManage = lazy(() => import('./views/Warning/ruleManage'))
const BigScreen = lazy(() => import('./views/Warning/bigScreen'))
const Search = lazy(() => import('./views/Search/search'))
const SearchConfigure = lazy(() => import('./views/Search/searchConfigure'))
const SearchContent = lazy(() => import('./views/Search/searchContent'))
const FreePanel = lazy(() => import('./views/Search/freePanel'))
const SearchHistory = lazy(() => import('./views/Search/searchHistory'))
const SearchCollect = lazy(() => import('./views/Search/searchCollect'))
const SearchOverview = lazy(() => import('./views/Search/searchOverview'))
const Welcome = lazy(() => import('./views/Welcome/welcome'))
const OperationLog = lazy(() => import('./views/OperationLog/operationLog'))
const WarnOperationLog = lazy(() => import('./views/OperationLog/operationLog'))
const SearchOperationLog = lazy(() => import('./views/OperationLog/operationLog'))
const Verification = lazy(() => import('./views/Verification/verification'))


export const history = createHistory()


class Routes extends Component {
  render() {
    return (
      <ConnectedRouter history={history}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path='/login' component={() => <Login />} />
            <Route path='/verification' component={() => <Verification />} />
            <Route path='/registerDetail/:id' component={() => <RegisterDetail />} />

            <Route path='/bigScreen' component={() => <BigScreen />} />
            <Route path='/' render={() =>
              localStorage.token ? <Pc>
                <Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    <Route path='/' exact render={() => <Redirect to='/welcome' />} />
                    <Route path='/welcome' component={() => <Welcome />} />
                    <Route path='/qualityControl' component={() => <QualityControl />} />
                    <Route path='/expertSelection' component={() => <ExpertSelection />} />
                    <Route path='/caseReport' component={() => <CaseReport />} />
                    <Route path='/qualityControlDetail/:id/:recordId' component={() => <QualityControlDetail />} />
                    <Route path='/qualityConfigure' component={() => <QualityConfigure />} />
                    <Route path='/caseDetail/:id' component={() => <CaseDetail />} />
                    <Route path='/expertReviewDetail/:id/:caseId/:isReview' component={() => <ExpertSelectionDetail />} />
                    <Route path='/pulseReport' component={() => <PulseReport />} />
                    <Route path='/tongueReport' component={() => <TongueReport />} />
                    <Route path='/faceReport' component={() => <FaceReport />} />
                    <Route path='/prescriptReport' component={() => <PrescriptReport />} />

                    {/* <Route path='/permission' component={() => <Permission />} /> */}
                    <Route path='/permission' component={() => <PermissionList />} />
                    <Route path='/warnPermission' component={() => <PermissionList />} />
                    <Route path='/searchPermission' component={() => <SearchPermission />} />
                    <Route path='/messageCenter' component={() => <MessageCenter />} />
                    <Route path='/operationLog' component={() => <OperationLog />} />
                    <Route path='/searchOperationLog' component={() => <SearchOperationLog />} />
                    <Route path='/warnOperationLog' component={() => <WarnOperationLog />} />
                    <Route path='/warnPermission' component={() => <Permission />} />
                    <Route path='/roleControll' component={() => <RoleControll />} />

                    <Route path='/personal/:id' component={() => <Personal />} />

                    <Route path='/ecgUserList' component={() => <EcgUserList />} />
                    <Route path='/warnUserList' component={() => <EcgUserList />} />
                    <Route path='/searchUserList' component={() => <SearchUserList />} />
                    <Route path='/addEcgUser/:id?' component={() => <AddEcgUser />} />

                    <Route path='/ecgHospitalList' component={() => <EcgHospitalList />} />
                    <Route path='/addEcgHospital/:id?' component={() => <AddEcgHospital />} />

                    <Route path='/addRegister' component={() => <AddRegister />} />
                    <Route path='/registerList' component={() => <RegisterList />} />
                    <Route path='/selectionOverview' component={() => <SelectionOverview />} />
                    <Route path='/qualityOverview' component={() => <QualityOverview />} />
                    <Route path='/projectDetail/:id' component={() => <ProjectDetail />} />
                    <Route path='/patientControl' component={() => <PatientControl />} />
                    <Route path='/patientDetail/:id' component={() => <PatientDetail />} />
                    <Route path='/warnList' component={() => <WarnList />} />
                    <Route path='/ruleManage' component={() => <RuleManage />} />
                    <Route path='/search' component={() => <Search />} />
                    <Route path='/searchContent' component={() => <SearchContent />} />
                    <Route path='/searchConfigure' component={() => <SearchConfigure />} />
                    <Route path='/searchFreePanel' component={() => <FreePanel />} />
                    <Route path='/searchHistory' component={() => <SearchHistory />} />
                    <Route path='/searchCollect' component={() => <SearchCollect />} />
                    <Route path='/searchOverview' component={() => <SearchOverview />} />

                  </Switch>
                </Suspense>
              </Pc> : <Redirect to='/login' />} />
          </Switch>

        </Suspense>
      </ConnectedRouter>
    )
  }
}

export default Routes;
