import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { Layout } from 'antd'
import SiderBar from './views/Layouts/siderBar'
import Bread from './views/Layouts/Bread'
import Header from './views/Layouts/header'
import Tabs from './views/ReportDetail/index'

class Pc extends Component {
  render() {
    const { Content } = Layout
    const isSearchPage =
      (window.location.href.includes('search') &&
        !window.location.href.includes('searchUserList') &&
        !window.location.href.includes('searchPermission') &&
        !window.location.href.includes('searchConfigure')) ||
      window.location.href.includes('freePanel') ||
      window.location.href.includes('searchUserList') ||
      window.location.href.includes('searchPermission')
    let style
    let styleContainer, isSiderBar
    const ReportPage = [
      '/pulseReport',
      '/tongueReport',
      '/faceReport',
      '/prescriptReport'
    ]
    ReportPage.includes(this.props.location.pathname)
      ? (isSiderBar = false)
      : (isSiderBar = true)
    if (window.isPad) {
      style = { marginLeft: 80, overflowY: 'hidden' }
      styleContainer = { minHeight: '100vh', position: 'relative' }
    } else {
      // style = { marginLeft: isSiderBar ? 180 : 0, overflowY: 'hidden', minWidth: 1345 }
      style = { minWidth: 1710 }
      styleContainer = {
        // minHeight: '100vh',
        position: 'relative',
        minWIdth: 1350
      }
    }
    return (
      <Layout style={styleContainer}>
        {/* {isSiderBar && <SiderBar />} */}
        <Layout style={style}>
          <Header />
          {isSiderBar ? null : <Tabs />}
          <Content
            style={{
              margin: !isSearchPage ? '16px 16px 0 16px' : 0,
              borderRadius: 4
            }}
          >
            {this.props.children}
          </Content>
        </Layout>
      </Layout>
    )
  }
}
export default withRouter(Pc)

export class Mobile extends Component {
  componentDidMount() {
    this.setFontSize()
    // const that = this
    // window.onresize = function() {
    //   that.setFontSize()
    // }
  }
  setFontSize = () => {
    let oHtml = document.documentElement
    let screenWidth = oHtml.clientWidth
    let screenHeight = oHtml.clientHeight
    if (screenHeight / screenWidth < 1.6) {
      screenWidth = screenHeight / 1.6
    }

    oHtml.style.fontSize = `${screenWidth / (750 / 40)}px`
  }
  render() {
    return <div>{this.props.children}</div>
  }
}
