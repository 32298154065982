const sm2 = require('sm-crypto').sm2

const publicKey = '049636bea8fcaa70d05fbf596a4bda4ad6268d54c4191c3a5f9effa193dcb4c0a74a7784dfb9e2fbf140e60108f09c728a2086b740f217c1698e0605d2fd7ec09e'
const privateKey = '74dffdb46681893f821822b05496fa54eb1ea6bda7e88136d568bc76e9f4299d'

// 加密
export const encryptData = (value, cipherMode = 1) => {
    return sm2.doEncrypt(value, publicKey, cipherMode)
}

// 解密
export const decryptData = (value, cipherMode = 1) => {
    if (value) {
        return sm2.doDecrypt(value, privateKey, cipherMode)
    }
}