import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { decryptData } from '../../utils/sm2Encrypt'

import { history } from '../../routers'
import { Layout } from 'antd'
import ScanModal from './scanModal'
import CommonModal from '../../components/CommonModal/commonModal'
import MyIcon from '../../components/myIcon'
import { Avatar, Row, Col, Tooltip, Menu, Dropdown, Icon } from 'antd'
import './layouts.styl'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      qualityList: [
        {
          name: '患者管理',
          path: '/patientControl',
          permissionUser: ['机构管理员', '超级管理员', '机构医生']
        },
        {
          name: '质控概况',
          path: '/qualityOverview',
          permissionUser: ['卫健人员', '超级管理员']
        },
        {
          name: '质控管理',
          path: '/qualityControl',
          permissionUser: ['机构管理员', '机构医生', '卫健人员', '超级管理员']
        },
        {
          name: '案例概况',
          path: '/selectionOverview',
          permissionUser: ['卫健人员', '超级管理员']
        },
        {
          name: '专家评选',
          path: '/expertSelection',
          permissionUser: ['评审专家', '超级管理员']
        },
        { name: '案例上报', path: '/caseReport', permissionUser: ['机构医生'] },
        { name: '综合配置', path: '/qualityConfigure' },
        {
          name: '用户管理',
          path: '/ecgUserList',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '权限管理',
          path: '/permission',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '组织管理',
          path: '/ecgHospitalList',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '操作日志',
          path: '/operationLog',
          permissionUser: ['超级管理员', '卫健人员']
        }
      ],
      selectionList: [
        {
          name: '案例评选',
          path: '/selectionOverview',
          permissionUser: ['卫健人员', '超级管理员']
        },
        {
          name: '专家评选',
          path: '/expertSelection',
          permissionUser: ['评审专家', '超级管理员']
        },
        { name: '案例上报', path: '/caseReport', permissionUser: ['机构医生'] }
      ],
      searchList: [
        {
          name: '搜索首页',
          path: '/search',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '搜索概况',
          path: '/searchOverview',
          permissionUser: ['超级管理员']
        },
        {
          name: '自由看板',
          path: '/searchFreePanel',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '搜索收藏',
          path: '/searchCollect',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '搜索历史',
          path: '/searchHistory',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '知识图谱',
          path: '/chart',
          permissionUser: ['超级管理员', '卫健人员']
        },
        // {
        //   name: '搜索内容',
        //   path: '/searchContent',
        //   permissionUser: ['超级管理员', '卫健人员']
        // },
        {
          name: '用户管理',
          path: '/searchUserList',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '权限管理',
          path: '/searchPermission',
          permissionUser: ['超级管理员', '卫健人员']
        },
        { name: '综合配置', path: '/searchConfigure' },
        {
          name: '操作日志',
          path: '/searchOperationLog',
          permissionUser: ['超级管理员', '卫健人员']
        }
      ],
      bigScreenList: [
        { name: '预警管理', path: '/warnList' },
        { name: '规则管理', path: '/ruleManage' },
        { name: '数据大屏', path: '/bigScreen' },
        {
          name: '用户管理',
          path: '/warnUserList',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '权限管理',
          path: '/warnPermission',
          permissionUser: ['超级管理员', '卫健人员']
        },
        {
          name: '操作日志',
          path: '/warnOperationLog',
          permissionUser: ['超级管理员', '卫健人员']
        }
      ],
      permissionList: [
        { name: '用户管理', path: '/ecgUserList' },
        { name: '组织管理', path: '/ecgHospitalList' }
        // { name: '权限管理', path: '/permissionControl' },
      ],
      active: '病案质控'
      // tabActive: '',
    }
  }
  componentDidMount() {
    if (!localStorage.token) return history.push('/login')
    this.props.setBreadPosition(localStorage.breadPosition)
    if (
      window.location.pathname !== '/login' &&
      window.location.pathname !== '/h5/h5login'
    ) {
      this.props.getCurrentUser()
    }
    if (location.pathname.includes('search')) {
      this.props.getThemeConfig()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.personal.userInfo !== this.props.personal.userInfo) {
      const { moduleNames } = this.props.personal.userInfo.permission
      const { qualityList, bigScreenList, searchList } = this.state
      const newQualityList = qualityList.filter(item => {
        return moduleNames.includes(item.name)
      })
      const newBigScreenList = bigScreenList.filter(item => {
        return moduleNames.includes(item.name)
      })
      const newSearchList = searchList.filter(item => {
        return moduleNames.includes(item.name)
      })
      this.setState({
        qualityList: newQualityList,
        bigScreenList: newBigScreenList,
        searchList: newSearchList
      })
    }
    if (
      location.pathname.includes('search') &&
      prevProps.location.pathname !== location.pathname
    ) {
      this.props.getThemeConfig()
    }
  }

  goLogin = () => {
    if (!this.state.image) {
      history.push('/login')
    }
  }
  logout = () => {
    this.CommonModal.show({
      title: '退出登录',
      msg: '确认退出登录吗？',
      modalType: 'confirm',
      okFunc: this.okFunc
    })
  }
  okFunc = () => {
    localStorage.clear('token')
    history.push('/login')
  }
  handQuality = () => { }
  handMenu = path => {
    console.log(path)
  }
  handMenuItem = v => {
    console.log(v.path)
    if (v.path === '/chart') return open('http://122.228.51.102:7474/')
    if (v.path == this.props.location.pathname) return
    this.props.history.push(v.path)
  }
  handTab = (e, o) => {
    e.preventDefault()
    this.setState({ active: o.name })
    if (
      (o.path == '/selectionOverview' &&
        (this.props.location.pathname.split('/')[1] == 'projectDetail' ||
          this.props.location.pathname == '/expertSelection' ||
          this.props.location.pathname == '/caseReport' ||
          this.props.location.pathname.split('/')[1] == 'expertReviewDetail' ||
          this.props.location.pathname.split('/')[1] == 'caseDetail')) ||
      (o.path == '/ecgUserList' &&
        this.props.location.pathname == '/ecgHospitalList') ||
      (o.path == '/warnList' &&
        (this.props.location.pathname == '/bigScreen' ||
          this.props.location.pathname == '/ruleManage' ||
          this.props.location.pathname == '/warnUserList' ||
          this.props.location.pathname == '/warnPermission')) ||
      (o.path == '/search' &&
        (this.props.location.pathname == '/searchContent' ||
          this.props.location.pathname == '/searchHistory' ||
          this.props.location.pathname == '/searchCollect' ||
          this.props.location.pathname == '/searchFreePanel' ||
          this.props.location.pathname == '/searchUserList' ||
          this.props.location.pathname == '/searchPermission' ||
          this.props.location.pathname == '/searchConfigure' ||
          this.props.location.pathname == '/searchOverview')) ||
      (o.path == '/patientControl' &&
        (this.props.location.pathname.split('/')[1] == 'qualityControlDetail' ||
          this.props.location.pathname.split('/')[1] == 'patientDetail' ||
          this.props.location.pathname.split('/')[1] == 'projectDetail' ||
          this.props.location.pathname == '/qualityControl' ||
          this.props.location.pathname == '/expertSelection' ||
          this.props.location.pathname == '/ecgUserList' ||
          this.props.location.pathname == '/ecgHospitalList' ||
          this.props.location.pathname == '/permission' ||
          this.props.location.pathname.split('/')[1] == 'expertReviewDetail' ||
          this.props.location.pathname == '/selectionOverview' ||
          this.props.location.pathname == '/caseReport' ||
          this.props.location.pathname == '/qualityConfigure' ||
          this.props.location.pathname.split('/')[1] == 'caseDetail' ||
          this.props.location.pathname == '/qualityOverview'))
    )
      return
    if (o.path && o.path !== this.props.location.pathname)
      this.props.history.push(o.path)
  }
  render() {
    const { Header } = Layout
    const { authentication, realName, pic, userInfo } = this.props.personal
    // console.log(userInfo)
    let isSiderBar
    const ReportPage = [
      '/pulseReport',
      '/tongueReport',
      '/faceReport',
      '/prescriptReport'
    ]
    ReportPage.includes(this.props.location.pathname)
      ? (isSiderBar = false)
      : (isSiderBar = true)

    const text = (
      <div
        className="toolTipAction"
        style={{
          width: '116px',
          // height: '35px',
          fontSize: '14px',
          color: '#333333',
          fontWeight: 400,
          textAlign: 'center'
        }}
      >
        <p
          onClick={() => {
            history.push('/personal/info')
          }}
        >
          个人信息
        </p>
        <p
          onClick={() => {
            history.push('/personal/restPwd')
          }}
        >
          修改密码
        </p>
        {/* <p onClick={() => { history.push('/personal/realName') }}>关于我们</p> */}
        <p onClick={this.logout}>退出登录</p>
      </div>
    )
    const qualityMenu = (
      <Menu>
        {this.state.qualityList.map((v, i) => (
          <Menu.Item
            key={v.path}
            onClick={() => this.handMenuItem(v)}
            style={{
              background:
                this.props.location.pathname == v.path ||
                  (v.path == '/qualityControl' &&
                    this.props.location.pathname.split('/')[1] ==
                    'qualityControlDetail') ||
                  (v.path == '/patientControl' &&
                    this.props.location.pathname.split('/')[1] ==
                    'patientDetail') ||
                  (v.path == '/expertSelection' &&
                    this.props.location.pathname.split('/')[1] ==
                    'expertReviewDetail') ||
                  (v.path == '/selectionOverview' &&
                    this.props.location.pathname.split('/')[1] ==
                    'projectDetail') ||
                  (v.path == '/caseReport' &&
                    this.props.location.pathname.split('/')[1] == 'caseDetail')
                  ? '#F9F4EF'
                  : '#fff'
            }}
          >
            <div>{v.name}</div>
          </Menu.Item>
        ))}
      </Menu>
    )
    const selectionMenu = (
      <Menu>
        {this.state.selectionList.map((v, i) => (
          <Menu.Item
            key={v.path}
            onClick={() => this.handMenuItem(v)}
            style={{
              background:
                this.props.location.pathname == v.path ||
                  (v.path == '/selectionOverview' &&
                    this.props.location.pathname.split('/')[1] ==
                    'projectDetail') ||
                  (v.path == '/expertSelection' &&
                    this.props.location.pathname.split('/')[1] ==
                    'expertReviewDetail') ||
                  (v.path == '/patientControl' &&
                    this.props.location.pathname.split('/')[1] ==
                    'patientDetail') ||
                  (v.path == '/caseReport' &&
                    this.props.location.pathname.split('/')[1] == 'caseDetail')
                  ? '#F9F4EF'
                  : '#fff'
            }}
          >
            <div>{v.name}</div>
          </Menu.Item>
        ))}
      </Menu>
    )
    const searchListMenu = (
      <Menu>
        {this.state.searchList.map((v, i) => (
          <Menu.Item
            key={v.path}
            onClick={() => this.handMenuItem(v)}
            style={{
              background:
                this.props.location.pathname == v.path ? '#F9F4EF' : '#fff'
            }}
          >
            <div>{v.name}</div>
          </Menu.Item>
        ))}
      </Menu>
    )
    const dataBigScreenMenu = (
      <Menu>
        {this.state.bigScreenList.map((v, i) => (
          <Menu.Item
            key={v.path}
            onClick={() => this.handMenuItem(v)}
            style={{
              background:
                this.props.location.pathname == v.path ? '#F9F4EF' : '#fff'
            }}
          >
            <div>{v.name}</div>
          </Menu.Item>
        ))}
      </Menu>
    )
    const permissionMenu = (
      <Menu>
        {this.state.permissionList.map((v, i) => (
          <Menu.Item
            key={v.path}
            onClick={() => this.handMenuItem(v)}
            style={{
              background:
                this.props.location.pathname == v.path ? '#F9F4EF' : '#fff'
            }}
          >
            <div>{v.name}</div>
          </Menu.Item>
        ))}
      </Menu>
    )
    const qualityPage = [
      '/patientControl',
      '/qualityOverview',
      '/qualityControl',
      '/projectDetail',
      '/expertSelection',
      '/patientDetail',
      '/qualityControlDetail',
      '/expertReviewDetail',
      '/selectionOverview',
      '/caseReport',
      '/qualityConfigure',
      '/ecgUserList',
      '/ecgHospitalList',
      '/permission',
      '/caseDetail'
    ]
    const isQuality =
      qualityPage.includes(this.props.location.pathname) ||
      qualityPage.includes('/' + this.props.location.pathname.split('/')[1])
    const patientControlPage = ['/patientControl', '/patientDetail']
    const isPatientControl =
      patientControlPage.includes(this.props.location.pathname) ||
      patientControlPage.includes(
        '/' + this.props.location.pathname.split('/')[1]
      )
    const selectionPage = [
      // '/selectionOverview',
      // '/projectDetail',
      // '/expertSelection',
      // '/expertReviewDetail',
      // '/caseReport',
      // '/caseDetail'
    ]
    const isSelection =
      selectionPage.includes(this.props.location.pathname) ||
      selectionPage.includes('/' + this.props.location.pathname.split('/')[1])
    const userPage = ['/ecgUserList', '/ecgHospitalList', '/addEcgUser']
    const isUser = userPage.includes(this.props.location.pathname)
    const bigScreenPage = [
      '/warnList',
      '/ruleManage',
      '/bigScreen',
      '/warnUserList',
      '/warnPermission'
    ]
    const isBigScreen = bigScreenPage.includes(this.props.location.pathname)
    const searchPage = [
      '/search',
      '/searchContent',
      '/searchHistory',
      '/searchCollect',
      '/searchFreePanel',
      '/searchUserList',
      '/searchPermission',
      '/searchConfigure',
      '/searchOverview'
    ]
    const isSearch = searchPage.includes(this.props.location.pathname)
    return (
      <div className="header-main">
        <CommonModal
          onRef={e => {
            this.CommonModal = e
          }}
        />

        <Header
          theme="dark"
          style={
            {
              // background: !isSiderBar ? '#fff' : '#FFFAF7', padding: '0 0px', height: 64, boxShadow: '0px 0px 6px 0px rgba(219,222,229,1)',
            }
          }
        >
          <Row type="flex" justify="space-between" align="middle">
            <Col className="header-left">
              <img
                style={{ cursor: 'pointer', marginRight: 120 }}
                src={require('../../assets/images/logo.png')}
                alt=""
              />
              <div className="select-box">
                <div className="select-list">
                  {userInfo.permission &&
                    (userInfo.permission.system === 1 ||
                      userInfo.permission.system === -1) && (
                      <Dropdown overlay={qualityMenu} trigger={['click']}>
                        <div
                          className={`ant-dropdown-link ${isQuality ? 'active' : ''
                            }`}
                        // onClick={e =>
                        // this.handTab(e, {
                        //   name: '患者管理',
                        //   path: '/patientControl'
                        // })
                        // }
                        >
                          <span>病案质控子系统</span>
                          <Icon type="caret-down" />
                        </div>
                      </Dropdown>
                    )}
                  {userInfo.permission &&
                    (userInfo.permission.system === 2 ||
                      userInfo.permission.system === -1) && (
                      <Dropdown overlay={dataBigScreenMenu} trigger={['click']}>
                        {/* <div className={`ant-dropdown-link ${isBigScreen ? 'actives' : ''}`} onClick={e => this.handTab(e, { name: '数据预警', path: '/warnList' })}> */}
                        <div
                          className={`ant-dropdown-link ${isBigScreen ? 'active' : ''
                            }`}
                        // onClick={e =>
                        //   this.handTab(e, {
                        //     name: '数据预警',
                        //     path: '/warnList'
                        //   })
                        // }
                        >
                          <span>数据预警子系统</span>
                          <Icon type="caret-down" />
                        </div>
                      </Dropdown>
                    )}
                  {userInfo.permission &&
                    (userInfo.permission.system === 3 ||
                      userInfo.permission.system === -1) && (
                      <Dropdown overlay={searchListMenu} trigger={['click']}>
                        {/* <div className={`ant-dropdown-link ${isSearch ? 'actives' : ''}`} onClick={e => this.handTab(e, { name: '智能搜索', path: '/search' })}> */}
                        <div
                          className={`ant-dropdown-link ${isSearch ? 'active' : ''
                            }`}
                        // onClick={e =>
                        //   this.handTab(e, {
                        //     name: '智能搜索',
                        //     path: '/search'
                        //   })
                        // }
                        >
                          <span>智能搜索子系统</span>
                          <Icon type="caret-down" />
                        </div>
                      </Dropdown>
                    )}
                </div>
              </div>
            </Col>
            <Col style={{ paddingRight: 52 }}>
              <div
                style={{
                  marginRight: 10,
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center'
                }}
                className="modal"
              >
                {userInfo.permission &&
                  (userInfo.permission.system === -1 ||
                    userInfo.permission.system === 1) && (
                    <img
                      src={require('../../assets/images/lingdang.svg')}
                      alt=""
                      style={{
                        marginRight: 20,
                        fontSize: 20,
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        if (this.props.location.pathname !== '/messageCenter') {
                          this.props.history.push('/messageCenter')
                        }
                      }}
                    />
                  )}
                <Tooltip placement="bottomRight" title={text}>
                  <Row type="flex" justify="space-between" align="middle">
                    {/* <div
                    style={{
                      marginRight: 10,
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    className="modal"
                  > */}
                    <span style={{ marginRight: 10, fontSize: 20 }}>
                      {decryptData(userInfo.realName)}
                    </span>
                    <MyIcon
                      type="icon-touxiang"
                      style={{
                        fontSize: 20,
                        background: '#F6F6F9',
                        borderRadius: '50%',
                        padding: '6px'
                      }}
                    />
                  </Row>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Header>
      </div>
    )
  }
}

const mapState = state => {
  return {
    state: state.Layouts,
    personal: state.Personal
  }
}

const mapDispatch = dispatch => {
  const { Layouts, Personal, Search } = dispatch
  const { setBreadPosition } = Layouts
  const { getCurrentUser } = Personal
  const { getThemeConfig } = Search

  return {
    setBreadPosition: pos => setBreadPosition(pos),
    getCurrentUser: pos => getCurrentUser(pos),
    getThemeConfig: pos => getThemeConfig(pos)
  }
}

export default withRouter(connect(mapState, mapDispatch)(Header))
